import React from 'react';
import { compose, withState } from 'recompose';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';

import device from '../../../device';

const Image = () => (
    <StaticQuery
        query={graphql`
            query {
                placeholderImage: file(relativePath: { eq: "achievements/achievement7.jpg" }) {
                    childImageSharp {
                        fluid(maxHeight: 300) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        `}
        render={data => <Img fluid={data.placeholderImage.childImageSharp.fluid} />}
    />
);

const AchievementContainer = styled.div`
    position: relative;
    text-align: center;
    height: 100%;

    @media ${device.laptop} {
        display: flex;
    }
`;

const ImageContainer = styled.div`
    opacity: ${({ isTextVisible }: { isTextVisible: boolean }) => (isTextVisible ? 0.2 : 1)};

    @media ${device.laptop} {
        width: 25rem;
        opacity: 1;
        margin: auto 0;
    }
`;

const TextContainerOuter = styled.div`
    position: absolute;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: ${props => (props.isTextVisible ? 1 : 0)};

    @media ${device.laptop} {
        opacity: 1;
        transform: translate(0%, 0%);
        top: 0;
        left: 0;
        position: relative;
    }
`;

const TextContainerInner = styled.div`
    display: flex;
    flex-direction: column;
    width: 90%;
    height: 100%;
    direction: rtl;
    white-space: normal;
    line-height: 1.1rem;
`;

const FirstLine = styled.div`
    font-size: 1.3rem;
    line-height: 1.3rem;
    font-weight: bold;
    margin-top: 0.5rem;

    @media ${device.tablet} {
        font-size: 2rem;
        margin: 2rem auto;
    }

    @media ${device.laptop} {
        font-size: 1.5rem;
        line-height: 2rem;
        margin: 1rem auto;
    }
`;

const Text = styled.div`
    padding: 0.5rem 0;

    @media ${device.tablet} {
        font-size: 1.5rem;
        line-height: 2rem;
    }

    @media ${device.laptop} {
        font-size: 1rem;
        padding: 0 0;
    }
`;

const LastLine = styled.div`
    font-weight: bold;

    @media ${device.tablet} {
        font-size: 2rem;
        line-height: 2.5rem;
        margin-top: 1rem;
    }

    @media ${device.laptop} {
        font-size: 1.7rem;
    }
`;

const Achievement = ({ isTextVisible, setIsTextVisible }) => (
    <AchievementContainer onClick={() => setIsTextVisible(!isTextVisible)}>
        <ImageContainer isTextVisible={isTextVisible}>
            <Image />
        </ImageContainer>
        <TextContainerOuter isTextVisible={isTextVisible}>
            <TextContainerInner>
                <FirstLine>מחיאות כפיים לאלופה שלי</FirstLine>
                <Text>
                    <div>(בלי שם ופנים) 😊... אבל מספיקה לי התמונה והתוצאה המטריפה שלה. </div>
                    <div>
                        היא לא מוותרת על שום אימון, עובדת באימונים קשה. שריר העצלנות שלה ברח ממזמן. בטן שטוחה היא קיבלה
                        😊
                    </div>
                </Text>
                <LastLine>מתמידה וחזקה מתמיד! גאה בך נורא. 🙌</LastLine>
            </TextContainerInner>
        </TextContainerOuter>
    </AchievementContainer>
);

export default compose(withState('isTextVisible', 'setIsTextVisible', false))(Achievement);

export const Achievement7Image = Image;
