import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';

import device from '../../../device';

const Image = () => (
    <StaticQuery
        query={graphql`
            query {
                placeholderImage: file(relativePath: { eq: "achievements/achievement1.jpg" }) {
                    childImageSharp {
                        fluid(maxHeight: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        `}
        render={data => <Img fluid={data.placeholderImage.childImageSharp.fluid} />}
    />
);

const ImageContainer = styled.div`
    @media ${device.laptop} {
        width: 22rem;
        margin: 0 auto;
    }
`;

const Achievement = (
    <ImageContainer>
        <Image />
    </ImageContainer>
);

export default () => Achievement;

export const Achievement1Image = Image;
