import React from 'react';
import { compose, withState } from 'recompose';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';

import { isDesktop } from '../../../utils/deviceDetector';
import device from '../../../device';

const Image = () => (
    <StaticQuery
        query={graphql`
            query {
                placeholderImage: file(relativePath: { eq: "achievements/achievement4.jpg" }) {
                    childImageSharp {
                        fluid(maxHeight: 300) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        `}
        render={data => <Img fluid={data.placeholderImage.childImageSharp.fluid} />}
    />
);

const AchievementContainer = styled.div`
    position: relative;
    text-align: center;
    height: 100%;

    @media ${device.laptop} {
        display: flex;
    }
`;

const ImageContainer = styled.div`
    opacity: ${({ isTextVisible }: { isTextVisible: boolean }) => (isTextVisible ? 0.2 : 1)};

    @media ${device.laptop} {
        width: 25rem;
        opacity: 1;
        margin: auto 0;
    }
`;

const TextContainerOuter = styled.div`
    position: absolute;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: ${props => (props.isTextVisible ? 1 : 0)};

    @media ${device.laptop} {
        opacity: 1;
        transform: translate(0%, 0%);
        top: 0;
        left: 0;
        position: relative;
    }
`;

const TextContainerInner = styled.div`
    display: flex;
    flex-direction: column;
    width: 90%;
    direction: rtl;
    white-space: normal;
    height: 100%;
    line-height: 1.1rem;

    @media ${device.tablet} {
        width: 95%;
    }

    @media ${device.laptop} {
        width: 90%;
    }
`;

const Text = styled.div`
    font-size: 0.7rem;
    width: 90%;
    margin: 0.7rem auto;

    @media ${device.tablet} {
        width: 90%;
        font-size: 1.3rem;
        line-height: 1.9rem;
        margin: 1rem auto;
    }

    @media ${device.laptop} {
        font-size: 0.9rem;
    }
`;

const Achievement = ({ isTextVisible, setIsTextVisible }) => (
    <AchievementContainer onClick={() => setIsTextVisible(!isTextVisible)}>
        <ImageContainer isTextVisible={isTextVisible}>
            <Image />
        </ImageContainer>
        <TextContainerOuter isTextVisible={isTextVisible}>
            <TextContainerInner>
                <Text>
                    <div>
                        "מי שמכיר אותי יודע שאחד הדברים האהובים עלי זה לאכול.... כשנרשמתי לסטודיו מיס פיטנס המטרה הייתה
                        פשוט להמשיך לאכול ולא לעלות במשקל... ובזכות אישה מדהימה אחת השגתי הרבה יותר מזה.... ירדתי במשקל,
                        צימצמתי היקפים ועליה במסת שריר.... תודה Michal Huri Salame שלימדת אותי לשלב בין התחביב שלי
                        (אוכל) לאורח חיים בריא יותר ועל כל הטיפים, העצות והשיעורים האנרגטיים והחזקים שאת מעבירה שמלאים
                        בהנאה! בסטודיו מיס פיטנס הכרתי גם חברות מדהימות שתמיד מפרגנות ותומכות והפכו את הדרך החדשה להרבה
                        יותר מהנה ואני יודעת שזכיתי בחברות לחיים!! בקיצור אין על הסטודיו הזה בעולם!
                    </div>
                    {isDesktop() && (
                        <div>
                            אז כן אפשר לאכול את מה שהגוף דורש ורוצה אם זה סודוך, קוראסון חמאה, פיצה, שוקולד או גלידה רק
                            צריך לדעת לעשות את זה בדרך נכונה ולדאוג לאורח חיים בריא בשילוב של תזונה וספורט!"
                        </div>
                    )}
                </Text>
            </TextContainerInner>
        </TextContainerOuter>
    </AchievementContainer>
);

export default compose(withState('isTextVisible', 'setIsTextVisible', false))(Achievement);

export const Achievement4Image = Image;
