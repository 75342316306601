import React from 'react';
import styled from 'styled-components';

import { IconButton, withGlowingBox, glowingOnDark } from '../ui';
import PhoneIcon from '../contactDetails/icons/PhoneIcon';
import linkProvider from '../contactDetails/linkProvider';
import { IconType } from '../../../types/icon';

const link = linkProvider(IconType.phone);

const CallUsText = styled.span`
    padding-left: 0.5rem;
    font-size: 1.2rem;
    font-weight: bold;
`;

const MainButtonComponent = ({ children }: { children: JSX.Element | JSX.Element[] }) => (
    <IconButton
        rounded
        style={{
            padding: '1rem 1.2rem',
            justifyContent: 'center',
            alignItems: 'center',
        }}
    >
        {children}
    </IconButton>
);

const GlowingButton = withGlowingBox(MainButtonComponent);

export default () => (
    <>
        <a href={link} target={'_blank'}>
            <GlowingButton overrideBorderRadius="100px" glowingKeyframes={glowingOnDark}>
                <CallUsText>התקשרי אלינו</CallUsText>
                <PhoneIcon size={20} />
            </GlowingButton>
        </a>
    </>
);
