import React from 'react';
import styled from 'styled-components';

import device from '../../device';

import { withTheme } from '../ui';
import { isMobile, isiPad, isDesktop } from '../../utils/deviceDetector';
import CallUsActionButton from './CallUsActionButton';
import ContactUsActionButton from './ContactUsActionButton';
import BackgroundImage from './BackgroundImage';
import MobileBackgroundImage from './MobileBackgroundImage';

const BackgroundContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 80vh;

    @media ${device.laptop} {
        height: 120vh;
    }
`;

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex: 2;

    @media ${device.tablet} {
        flex: 2.5;
    }

    @media ${device.laptop} {
        justify-content: flex-end;
        flex: 1.5;
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;

    @media ${device.laptop} {
        align-items: flex-start;
        flex: 2;
        margin-top: 4rem;
    }
`;

const PrimaryBackgroundText = styled.div`
    font-size: 2.3rem;
    color: ${theme => theme.secondary};
    text-align: center;
    margin: 0.8rem;
    font-weight: 900;

    @media ${device.tablet} {
        font-size: 3.8rem;
    }

    @media ${device.laptop} {
        font-size: 4rem;
    }
`;

const SecondaryBackgroundText = styled.div`
    font-size: 1.5rem;
    color: ${theme => theme.secondary};
    text-align: center;
    margin: 0.5rem;
    font-weight: 500;

    @media ${device.tablet} {
        font-size: 2.5rem;
        margin: 1.8rem;
    }

    @media ${device.laptop} {
        font-size: 2.5rem;
        margin: 2rem;
    }
`;

const DescriptiveBackgroundText = styled.div`
    font-size: 1rem;
    color: ${theme => theme.secondary};
    text-align: center;

    @media ${device.tablet} {
        font-size: 1.5rem;
        margin: 0.2rem;
    }

    @media ${device.laptop} {
        font-size: 1.5rem;
        margin-top: 0.5rem;
    }
`;

const BGImage = isMobile() ? MobileBackgroundImage : BackgroundImage;

export default withTheme(({ theme }) => (
    <BGImage>
        <BackgroundContainer>
            <TextContainer>
                <PrimaryBackgroundText {...theme}>סטודיו מיס פיטנס</PrimaryBackgroundText>
                <SecondaryBackgroundText {...theme}>
                    סטודיו <span style={{ fontWeight: 'bold', color: theme.primary }}>לנשים</span> חזקות
                </SecondaryBackgroundText>
                <DescriptiveBackgroundText {...theme}>
                    <span style={{ color: theme.primary, fontWeight: 'bold' }}>עיצוב</span> |{' '}
                    <span style={{ color: theme.primary, fontWeight: 'bold' }}>פילאטיס</span> |{' '}
                    <span style={{ color: theme.primary, fontWeight: 'bold' }}>זומבה</span>
                </DescriptiveBackgroundText>
            </TextContainer>
            <ButtonContainer>
                {isiPad() || isDesktop() ? <ContactUsActionButton /> : <CallUsActionButton />}
            </ButtonContainer>
        </BackgroundContainer>
    </BGImage>
));
