import React from 'react';
import { compose, withState } from 'recompose';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';

import device from '../../../device';

const Image = () => (
    <StaticQuery
        query={graphql`
            query {
                placeholderImage: file(relativePath: { eq: "achievements/achievement5.jpg" }) {
                    childImageSharp {
                        fluid(maxHeight: 300) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        `}
        render={data => <Img fluid={data.placeholderImage.childImageSharp.fluid} />}
    />
);

const AchievementContainer = styled.div`
    position: relative;
    text-align: center;

    @media ${device.laptop} {
        display: flex;
        height: 100%;
    }
`;

const ImageContainer = styled.div`
    opacity: ${({ isTextVisible }: { isTextVisible: boolean }) => (isTextVisible ? 0.2 : 1)};

    @media ${device.laptop} {
        width: 25rem;
        opacity: 1;
        margin: auto 0;
    }
`;

const TextContainerOuter = styled.div`
    position: absolute;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: ${props => (props.isTextVisible ? 1 : 0)};

    @media ${device.laptop} {
        opacity: 1;
        transform: translate(0%, 0%);
        top: 0;
        left: 0;
        position: relative;
        margin: auto 0;
    }
`;

const TextContainerInner = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 90%;
    height: 100%;
    direction: rtl;
    white-space: normal;
    line-height: 1.3rem;
`;

const FirstLine = styled.div`
    font-size: 1.2rem;
    line-height: 1.5rem;
    font-weight: bold;
    margin: 1rem auto;

    @media ${device.tablet} {
        font-size: 2.1rem;
    }
    @media ${device.laptop} {
        font-size: 1.5rem;
    }
`;

const Text = styled.div`
    @media ${device.tablet} {
        font-size: 1.5rem;
        line-height: 2.5rem;
    }
    @media ${device.laptop} {
        font-size: 1rem;
        line-height: 1.5rem;
    }
`;

const LastLine = styled.div`
    font-weight: bold;
    margin: 1rem auto;

    @media ${device.tablet} {
        font-size: 2.2rem;
    }
    @media ${device.laptop} {
        font-size: 1.7rem;
    }
`;

const Achievement5 = ({ isTextVisible, setIsTextVisible }) => (
    <AchievementContainer onClick={() => setIsTextVisible(!isTextVisible)}>
        <ImageContainer isTextVisible={isTextVisible}>
            <Image />
        </ImageContainer>
        <TextContainerOuter isTextVisible={isTextVisible}>
            <TextContainerInner>
                <FirstLine>מאמא שלי האלופה!! </FirstLine>
                <Text>
                    <div>לא מוותרת על שום יום ואימון.</div>
                    <div>מכורה היא כבר כן 😀</div>
                    <div>חלום שלי שהגיעה לרגע הזה.</div>
                    <div>10 קילו פחות !!!!!</div>
                    <div>חטובה יותר, רזה ובריאה.</div>
                </Text>
                <LastLine>גאה בך ימאמא שלי</LastLine>
            </TextContainerInner>
        </TextContainerOuter>
    </AchievementContainer>
);

export default compose(withState('isTextVisible', 'setIsTextVisible', false))(Achievement5);

export const Achievement5Image = Image;
