import React from 'react';
import { withState } from 'recompose';
import styled from 'styled-components';
import { MdClose } from 'react-icons/md';

import device from '../../device';

import { IconButton, Modal, withGlowingBox, glowingOnDark } from '../ui';
import ContactUs from '../ContactUs';

const ContactUsText = styled.span`
    font-size: 1.3rem;
    font-weight: bold;

    @media ${device.tablet} {
        font-size: 1.5rem;
        margin: 1rem 1.2rem;
    }

    @media ${device.laptop} {
        font-size: 1.2rem;
        margin: 0.4rem 0.6rem;
    }
`;

const MainButtonComponent = ({ setIsOpen }: { setIsOpen: (boolean) => void }) => (
    <IconButton
        onClick={() => setIsOpen(true)}
        rounded
        style={{
            justifyContent: 'center',
            alignItems: 'center',
        }}
    >
        <ContactUsText>גם אני אישה חזקה 💪</ContactUsText>
    </IconButton>
);

const GlowingButton = withGlowingBox(MainButtonComponent);

const ContactUsActionButton = ({ isOpen, setIsOpen }: { isOpen: boolean; setIsOpen: (_: boolean) => void }) => (
    <>
        <GlowingButton overrideBorderRadius="100px" setIsOpen={setIsOpen} glowingKeyframes={glowingOnDark}>
            <MainButtonComponent />
        </GlowingButton>
        <Modal open={isOpen} closeOnClickOutside={() => setIsOpen(false)}>
            <div style={{ width: '90%' }}>
                <div onClick={() => setIsOpen(false)} style={{ alignSelf: 'left', marginTop: '0.7rem' }}>
                    <MdClose size={30} />
                </div>
                <ContactUs source="actionButton" />
            </div>
        </Modal>
    </>
);

export default withState('isOpen', 'setIsOpen', false)(ContactUsActionButton);
