import React from 'react';
import styled from 'styled-components';
import ImageGallery from 'react-image-gallery';

import { isDesktop } from '../../utils/deviceDetector';
import device from '../../device';
import { PhrasedTitle } from '../ui';

import {
    Achievement1,
    Achievement1Image,
    Achievement2,
    Achievement2Image,
    Achievement3,
    Achievement3Image,
    Achievement4,
    Achievement4Image,
    Achievement5,
    Achievement5Image,
    Achievement6,
    Achievement6Image,
    Achievement7,
    Achievement7Image,
    Achievement8,
    Achievement8Image,
} from './achievements';

const AccompaniedPhrasedTitle = styled.div`
    font-size: 1rem;
    margin-top: 1rem;
    text-align: center;
    font-weight: bold;

    @media ${device.tablet} {
        font-size: 1.5rem;
        line-height: 3rem;
    }

    @media ${device.laptop} {
        font-size: 1rem;
    }
`;

const ImageGalleryContainer = styled.div`
    margin: 0 auto;
    margin-top: 1.5rem;

    @media ${device.tablet} {
        width: 30rem;
    }

    @media ${device.laptop} {
        width: 50rem;
        margin-top: 2.5rem;
    }
`;

const items = [
    { renderItem: () => <Achievement2 />, renderThumbInner: () => <Achievement2Image /> },
    { renderItem: () => <Achievement8 />, renderThumbInner: () => <Achievement8Image /> },
    { renderItem: () => <Achievement4 />, renderThumbInner: () => <Achievement4Image /> },
    { renderItem: () => <Achievement3 />, renderThumbInner: () => <Achievement3Image /> },
    { renderItem: () => <Achievement7 />, renderThumbInner: () => <Achievement7Image /> },
    { renderItem: () => <Achievement6 />, renderThumbInner: () => <Achievement6Image /> },
    { renderItem: () => <Achievement5 />, renderThumbInner: () => <Achievement5Image /> },
    { renderItem: () => <Achievement1 />, renderThumbInner: () => <Achievement1Image /> },
];

export default props => (
    <>
        <div>
            <PhrasedTitle title="התמדה + הכוונה = הגשמת חלומות" {...props} />
            {!isDesktop() && <AccompaniedPhrasedTitle>(לחצי על התמונה לפרטים נוספים..)</AccompaniedPhrasedTitle>}
        </div>
        <ImageGalleryContainer>
            <ImageGallery items={items} isRTL slideInterval={2000} showFullscreenButton={false} showBullets />
        </ImageGalleryContainer>
    </>
);
