import React from 'react';
import { compose, withState } from 'recompose';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';

import device from '../../../device';

const Image = () => (
    <StaticQuery
        query={graphql`
            query {
                placeholderImage: file(relativePath: { eq: "achievements/achievement8.jpg" }) {
                    childImageSharp {
                        fluid(maxHeight: 300) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        `}
        render={data => <Img fluid={data.placeholderImage.childImageSharp.fluid} />}
    />
);

const AchievementContainer = styled.div`
    position: relative;
    text-align: center;
    height: 100%;

    @media ${device.laptop} {
        display: flex;
    }
`;

const ImageContainer = styled.div`
    opacity: ${({ isTextVisible }: { isTextVisible: boolean }) => (isTextVisible ? 0.2 : 1)};
    height: 100%;

    @media ${device.laptop} {
        width: 20rem;
        opacity: 1;
    }
`;

const TextContainerOuter = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: ${props => (props.isTextVisible ? 1 : 0)};

    @media ${device.laptop} {
        opacity: 1;
        transform: translate(0%, 0%);
        top: 0;
        left: 0;
        position: relative;
    }
`;

const TextContainerInner = styled.div`
    display: flex;
    line-height: 1.2rem;
    flex-direction: column;
    width: 90%;
    height: 100%;
    direction: rtl;
    white-space: normal;
`;

const FirstLine = styled.div`
    font-weight: bold;
    margin-top: 0.5rem;

    @media ${device.tablet} {
        font-size: 2rem;
        margin: 1rem auto;
    }

    @media ${device.laptop} {
        font-size: 1.5rem;
        margin-bottom: 1rem;
    }
`;

const Text = styled.div`
    font-size: 0.9rem;
    width: 80%;
    margin: 0 auto;
    line-height: 1.2rem;

    @media ${device.tablet} {
        font-size: 1.5rem;
        line-height: 2rem;
    }

    @media ${device.laptop} {
        font-size: 1rem;
    }
`;

const Achievement = ({ isTextVisible, setIsTextVisible }) => (
    <AchievementContainer onClick={() => setIsTextVisible(!isTextVisible)}>
        <div>
            <ImageContainer isTextVisible={isTextVisible}>
                <Image />
            </ImageContainer>
        </div>
        <TextContainerOuter isTextVisible={isTextVisible}>
            <TextContainerInner>
                <FirstLine>מאחורי כל אישה יש מטרה!</FirstLine>
                <Text>
                    <div>
                        נכון שכולנו רוצות להיות חטובות חזקות רזות..... אצלי השאלה הראשונה לכל לקוחה, מה המטרה שלך!
                    </div>
                    <div>
                        למזי האלופה שלי היתה <b>מטרה אחת - לטפל בכתף הקפואה שלה</b>. מזי עשתה ניתוח בכתף ולא יכלה להרים
                        אותה. בצורה קיצונית מאוד.
                    </div>
                    <div>לקחתי על עצמי את הפרויקט, והמשפט הראשון שלי אליה - תני לי חודשיים!</div>
                    <div>
                        בזכות האימונים, היחס האישי ורמת האימונים המדויקת, מזי הגיעה לטווח תנועה מלא!!! דבר שלא חוותה
                        שנים.
                    </div>
                </Text>
            </TextContainerInner>
        </TextContainerOuter>
    </AchievementContainer>
);

export default compose(withState('isTextVisible', 'setIsTextVisible', false))(Achievement);

export const Achievement8Image = Image;
