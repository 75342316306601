import React from 'react';
import styled from 'styled-components';

import device from '../device';

import Layout from '../components/layout';
import { BackgroundSection, AboutUsSection, AboutMeSection, AchievementsSection } from '../components/mainPage';
import SEO from '../components/seo';

/*
 Main page:
 1. Header                          V
 2. Image                           V
 3. About                           V
 4. Timetable                       V
 5. Contact us -> Send mail !       V 
 6. Contact details                 V
 7. Slider of achievements

 Pages:
 1. Main page
 2. Schedule
 3. Contact Us
 4. About Us
 5. Recomendations
 6. Lessons
 7. Pictures
 8. Receipts

 Extras:
 * Gatsby starter - add to Home Page
 * Firebase Hosting - Work on dev environment
 https://console.firebase.google.com/project/studio-ms-fitnes-1508130649213/hosting/main

 * Facebook Pixel - https://www.socialmediaexaminer.com/how-to-track-facebook-conversions-after-february-2017/
 
 * Google Ads
 
 * Google Maps:
    - Development API Key           V
    - Set alert on quota            V
    https://console.cloud.google.com/google/maps-apis/apis/maps-backend.googleapis.com/quotas?project=studio-ms-fitnes-1508130649213&folder&organizationId&supportedpurview=project&duration=PT1H
    - API Key as a secret
    - Billing measurements
    https://console.cloud.google.com/billing/015527-102B14-127288?project=studio-ms-fitnes-1508130649213&folder&organizationId&supportedpurview=project
*/

const IndexPage = props => (
    <Layout {...props} stickyHeader>
        <SEO
            title="חוגים לנשים | עיצוב בראשון לציון | פילאטיס בראשון לציון | זומבה בראשון לציון"
            keywords={[
                `פילאטיס בראשון לציון`,
                `זומבה בראשון לציון`,
                `עיצוב וחיטוב בראשון לציון`,
                `סטודיו לנשים בראשון לציון`,
            ]}
        />
        <BackgroundImageContainer>
            <BackgroundSection />
        </BackgroundImageContainer>
        <AboutUsContainer>
            <AboutUsSection />
        </AboutUsContainer>
        <AboutMeContainer>
            <AboutMeSection />
        </AboutMeContainer>
        <AchievementsContainer>
            <AchievementsSection />
        </AchievementsContainer>
    </Layout>
);

const BackgroundImageContainer = styled.div`
    margin-bottom: 1.45rem;
`;

const AboutUsContainer = styled.div`
    width: 75%;
    margin: 2.5rem auto;

    @media ${device.tablet} {
        margin: 4rem auto;
    }

    @media ${device.laptop} {
        margin: 3rem auto;
    }
`;

const AboutMeContainer = styled.div`
    width: 75%;
    margin: 2.5rem auto;

    @media ${device.tablet} {
        margin: 4rem auto;
    }

    @media ${device.laptop} {
        margin: 3rem auto;
    }
`;

const AchievementsContainer = styled.div`
    width: 75%;
    margin: 2.5rem auto;

    @media ${device.tablet} {
        margin: 4rem auto;
    }
`;

export default IndexPage;
