import React from 'react';
import { compose, withState } from 'recompose';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';

import { isDesktop } from '../../../utils/deviceDetector';
import device from '../../../device';

const Image = () => (
    <StaticQuery
        query={graphql`
            query {
                placeholderImage: file(relativePath: { eq: "achievements/achievement6.jpg" }) {
                    childImageSharp {
                        fluid(maxHeight: 300) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        `}
        render={data => <Img fluid={data.placeholderImage.childImageSharp.fluid} />}
    />
);

const AchievementContainer = styled.div`
    position: relative;
    text-align: center;
    height: 100%;

    @media ${device.laptop} {
        display: flex;
    }
`;

const ImageContainer = styled.div`
    opacity: ${({ isTextVisible }: { isTextVisible: boolean }) => (isTextVisible ? 0.2 : 1)};

    @media ${device.laptop} {
        width: 25rem;
        opacity: 1;
        margin: auto 0;
    }
`;

const TextContainerOuter = styled.div`
    position: absolute;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: ${props => (props.isTextVisible ? 1 : 0)};

    @media ${device.laptop} {
        opacity: 1;
        transform: translate(0%, 0%);
        top: 0;
        left: 0;
        position: relative;
    }
`;

const TextContainerInner = styled.div`
    display: flex;
    flex-direction: column;
    width: 90%;
    height: 100%;
    direction: rtl;
    white-space: normal;
    line-height: 0.8rem;
`;

const FirstLine = styled.div`
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 2rem;

    @media ${device.laptop} {
        margin-top: 1rem;
    }
`;

const Text = styled.div`
    font-size: 0.7rem;
    width: 80%;
    margin: 1rem auto;

    @media ${device.tablet} {
        font-size: 1.2rem;
        line-height: 1.5rem;
        margin: 1.5rem auto;
        width: 90%;
    }

    @media ${device.laptop} {
        font-size: 1rem;
        line-height: 1.7rem;
        margin: 0.3rem auto;
        width: 90%;
    }
`;

const LastLine = styled.div`
    font-weight: bold;

    @media ${device.tablet} {
        font-size: 2.5rem;
    }

    @media ${device.laptop} {
        line-height: 2.5rem;
        margin-bottom: 1rem;
    }
`;

const Achievement = ({ isTextVisible, setIsTextVisible }) => (
    <AchievementContainer onClick={() => setIsTextVisible(!isTextVisible)}>
        <ImageContainer isTextVisible={isTextVisible}>
            <Image />
        </ImageContainer>
        <TextContainerOuter isTextVisible={isTextVisible}>
            <TextContainerInner>
                <div>
                    {isDesktop() && (
                        <FirstLine>
                            "המוטיבציה מכוונת אותנו למטרות, להתנהגויות שיממשו את הרצון שלנו. היא הדלק והמצפן שמכוון את
                            מסע חיינו"
                        </FirstLine>
                    )}
                </div>
                <Text>
                    <div>
                        מאשה המדהימה, מתאמנת אצלי אוטוטו 4 שנים. אישה עם מטרה! לא מוותרת על שום אימון, שלוש פעמים בשבוע
                        אצלי בסטודיו, אין אצלה לא יכולה או לא רוצה. הכל יכולה!!! (עד שאומרת לה , מאשה בואי נירגע קצת
                        ...😋) אישה צעירה בנפשה והגזרה, 55555 הלוואי על כולנו ....
                    </div>
                    <div>
                        את אישה חזקה, עם כוח רצון להצלחה!!! בזכות האימונים הנכונים לגופך, לפי הקצב שלך את משיגה את הכל.
                        גאה בך ורוצה אותך אצלי לעוד שנים רבות של הנאה ואימונים.
                    </div>
                </Text>
                {isDesktop() && <LastLine>סטודיו לנשים חזקות!</LastLine>}
            </TextContainerInner>
        </TextContainerOuter>
    </AchievementContainer>
);

export default compose(withState('isTextVisible', 'setIsTextVisible', false))(Achievement);

export const Achievement6Image = Image;
