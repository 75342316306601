import React from 'react';
import styled from 'styled-components';

import device from '../../device';

const About = styled.div`
    @media ${device.tablet} {
        font-size: 1.5rem;
        line-height: 2rem;
    }

    @media ${device.laptop} {
        font-size: 1rem;
        line-height: 1.5rem;
    }
`;

const FirstLine = styled.span`
    font-size: 1.2rem;

    @media ${device.tablet} {
        font-size: 1.9rem;
    }

    @media ${device.laptop} {
        font-size: 1.3rem;
    }
`;

export default () => (
    <About id="about">
        <p>
            <FirstLine>הי, אני שמחה שהגעת לכאן 😊</FirstLine>
            <br />
            <span>בטח תרצי לשמוע קצת פרטים עלינו ועליי...</span>
        </p>
        <p>
            אני מאמינה שאנחנו, הנשים חזקות. אנחנו מתרוצצות מבוקר עד ערב, עובדות קשה בשביל כולם ומסיימות את היום מותשות.
            מגיע לנו משהו לעצמנו. בשבילנו. אנחנו. זמן קטן. רגע של כיף והנאה. להוכיח לעצמנו שאנחנו יכולות להגיע למטרות
            שלנו. לחלומות שלנו. להסתכל במראה ולהיות גאות!
        </p>
        <p>
            לכן פתחתי את הסטודיו שלי. סטודיו לחוגים שכולו עוצמה והנאה לנשים בלבד. סטודיו משפחתי. שיעזור לך להגיע לתוצאות
            שתמיד רצית. להוציא ממך את החוזקות האישיות. וכל זאת בהמון כיף, שמחה וצחוק. בזמן שנח לך, איך שנח לך. בעזרת
            צוות מקצועי ושיטה מנצחת ויחודית.
        </p>
        <p>רוצה להצטרף למשפחה?</p>
    </About>
);
