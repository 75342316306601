import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { default as GatsbyBackgroundImage } from 'gatsby-background-image';

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `StaticQuery`: https://gatsby.dev/staticquery
 */

const MobileBackgroundImage = ({ children }: { children: JSX.Element[] | JSX.Element }) => (
    <StaticQuery
        query={graphql`
            query {
                placeholderImage: file(relativePath: { eq: "58377.png" }) {
                    childImageSharp {
                        fluid(maxHeight: 300) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        `}
        render={data => (
            <GatsbyBackgroundImage Tag="section" fluid={data.placeholderImage.childImageSharp.fluid}>
                {children}
            </GatsbyBackgroundImage>
        )}
    />
);

export default MobileBackgroundImage;
