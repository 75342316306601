import React from 'react';
import { compose, withState } from 'recompose';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';

import device from '../../../device';

const Image = () => (
    <StaticQuery
        query={graphql`
            query {
                placeholderImage: file(relativePath: { eq: "achievements/achievement3.jpg" }) {
                    childImageSharp {
                        fluid(maxHeight: 300) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        `}
        render={data => <Img fluid={data.placeholderImage.childImageSharp.fluid} />}
    />
);

const AchievementContainer = styled.div`
    position: relative;
    text-align: center;
    height: 100%;

    @media ${device.laptop} {
        display: flex;
    }
`;

const ImageContainer = styled.div`
    opacity: ${({ isTextVisible }: { isTextVisible: boolean }) => (isTextVisible ? 0.2 : 1)};

    @media ${device.laptop} {
        width: 25rem;
        opacity: 1;
        margin: auto 0;
    }
`;

const TextContainerOuter = styled.div`
    position: absolute;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: ${props => (props.isTextVisible ? 1 : 0)};

    @media ${device.laptop} {
        opacity: 1;
        transform: translate(0%, 0%);
        top: 0;
        left: 0;
        position: relative;
    }
`;

const TextContainerInner = styled.div`
    display: flex;
    flex-direction: column;
    width: 90%;
    height: 100%;
    direction: rtl;
    white-space: normal;
    line-height: 1.1rem;
`;

const FirstLine = styled.div`
    font-weight: bold;
    margin-top: 1rem;

    @media ${device.tablet} {
        font-size: 1.7rem;
        line-height: 2rem;
        margin: 1rem auto;
    }

    @media ${device.laptop} {
    }
`;

const Text = styled.div`
    font-size: 0.7rem;
    width: 80%;
    margin: 0.7rem auto;

    @media ${device.tablet} {
        font-size: 1.5rem;
        line-height: 2rem;
        width: 90%;
    }

    @media ${device.laptop} {
        font-size: 1rem;
        width: 80%;
    }
`;

const Achievement = ({ isTextVisible, setIsTextVisible }) => (
    <AchievementContainer onClick={() => setIsTextVisible(!isTextVisible)}>
        <ImageContainer isTextVisible={isTextVisible}>
            <Image />
        </ImageContainer>
        <TextContainerOuter isTextVisible={isTextVisible}>
            <TextContainerInner>
                <FirstLine>
                    "המוטיבציה מכוונת אותנו למטרות, להתנהגויות שיממשו את הרצון שלנו. היא הדלק והמצפן שמכוון את מסע
                    חיינו."
                </FirstLine>
                <Text>
                    <div>
                        תכירו את נטע סמוחה. מתאמנת שלי מעל 7 שנים. נטע המהממת, אישה עם אנרגיות והמון שמחת חיים. טפו
                        טפו😀....
                    </div>
                    <div>
                        מתמידה באימונים, ולא משנה לה חג, חופשה.... (הרי בתאכלס הכל זה תירוצים) תמיד תדאג לשריין את ימי
                        הספורט שלה ולא תוותר לשנייה!{' '}
                    </div>
                </Text>
            </TextContainerInner>
        </TextContainerOuter>
    </AchievementContainer>
);

export default compose(withState('isTextVisible', 'setIsTextVisible', false))(Achievement);

export const Achievement3Image = Image;
