import React from 'react';
import styled from 'styled-components';

import device from '../../device';

import { withTheme, PhrasedTitle } from '../ui';

const About = styled.span`
    @media ${device.tablet} {
        font-size: 1.5rem;
        line-height: 2rem;
    }

    @media ${device.laptop} {
        font-size: 1rem;
        line-height: 1.5rem;
    }
`;

export default withTheme(props => (
    <div>
        <p>
            <PhrasedTitle title="ביחד איתך, בקצב שלך, החלום הופך למציאות" {...props} />
        </p>
        <p>
            <About>לכל אחת המטרה והחלום שלה - להרזות, להתחזק, להתחטב, להחלים מפציעה, להנות... </About>
        </p>
        <p>
            <About>
                הסטודיו שלנו קיים מזה 4 שנים במטרה<b> להפוך חלומות למציאות.</b> ישנם עשרות סיפורים על בנות שהפכו את
                חייהן לטובים יותר, וממצים יותר.
            </About>
        </p>
        <p>
            <About>
                מיכל סלמה, בעלת הסטודיו ומאמנת במקצועה עם ניסיון של יותר מ15 שנה. מקצועית מאוד ובעלת ידע נרחב בטיפול
                והחלמה מפציעות.&nbsp;
                <b style={{ color: props.theme.primary }}>ומגשימת חלומות </b>.
            </About>
        </p>
    </div>
));
